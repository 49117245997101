<template>
  <div class="header-nav">
    <!-- Logo envuelto en router-link para redirigir al dashboard -->
    <router-link to="/dashboard" class="menu-logo-link">
      <img src="@/assets/images/logo_blanco.png" alt="Logo" class="menu-logo" />
    </router-link>
    <button class="menu-toggle" @click="toggleMenu" v-if="isMobile">
      <span class="hamburger-icon">☰</span>
    </button>
    <nav class="menu" :class="{ open: menuOpen }">
      <div class="menu-links">
        <router-link
          to="/admin/users"
          class="menu-item"
          :class="{ active: isActive('/admin/users') }"
          @click="closeMenu"
        >
          USUARIOS
        </router-link>
      </div>
      <div class="dropdown-container">
        <AppHeaderDropdownAccnt />
      </div>
    </nav>
  </div>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt';

export default {
  name: 'AppHeaderNav',
  data() {
    return {
      menuOpen: false,
      isMobile: false,
    };
  },
  methods: {
    isActive(path) {
      return this.$route.path.startsWith(path);
    },
    closeMenu() {
      this.menuOpen = false;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  components: {
    AppHeaderDropdownAccnt,
  },
};
</script>


<style scoped>
.header-nav {
  background-color: #2d2654; /* Color similar al mostrado */
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}

.menu-logo {
  height: 30px;
  margin-right: 50px;
  margin-left: 30px;
}

.menu {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.menu-links {
  display: flex;
  gap: 30px; /* Espaciado entre los elementos */
  flex-grow: 1;
}

.menu-item {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
}

.menu-item:hover {
  background-color: #5a4fa3; /* Efecto hover */
}

.menu-item.active {
  border-bottom: 2px solid white !important; /* Línea debajo del activo */
}

.dropdown-container {
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
}

/* Estilo para el botón de menú en móviles */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
  z-index: 10;
}

.hamburger-icon {
  font-size: 30px;
}

/* Media Queries para hacer el menú responsivo */
@media (max-width: 768px) {
  .menu-links {
    display: none; /* Ocultamos los enlaces de menú por defecto */
    flex-direction: column;
    width: 100%;
    background-color: #2d2654;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 20px 0;
    z-index: 5;
  }

  .menu.open .menu-links {
    display: flex; /* Mostramos el menú cuando está abierto */
  }

  .menu-toggle {
    display: block; /* Mostramos el botón de menú en móviles */
  }
}

@media (max-width: 480px) {
  .menu-item {
    font-size: 14px;
  }
}
</style>
